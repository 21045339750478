.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
}

.slide button {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide p {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide section * {
    transition: all 0.3s ease;
}

.slide section img {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
}

.slide section span {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
}

.slide section span strong {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: .9s;
    transition-delay: .9s;
    opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    -webkit-transition-delay: 1.4s;
    transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
    -webkit-transition-delay: 1.5s;
    transition-delay: 1.5s;
}

.slide.animateOut h1 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}

.slide.animateOut p {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.slide.animateOut section span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.slide.animateOut section span strong {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}