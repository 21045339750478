@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myFooterBox{
    background-color: #7b8188;
    width: 100%;
    bottom: 0;
    color: white;
    height: 50px;
    position: relative;
    padding-top: 15px;
    text-align: center;
    font-weight: bold;
    opacity: 0.6;
    margin-top: 3%;
}


.headerLine{
    height: 2px;
    border-radius: 1px;
    margin-left: 20px;
    right: 20px;
    width: 90vh;
}
.myLinkHeader{
    pointer-events: all;
    color: currentColor;
    -webkit-text-decoration: #00aced;
            text-decoration: #00aced;
    cursor: pointer;
}
.pageHeaderModuleLinkBox{
    float: right;
    font-size: 18px;
    font-weight: bold;
    /*background-color: red;*/
    color:white;
    margin-top: 10px;
    margin-right: 10px;

}
.pageHeaderModuleLinkBox a{
    margin: 3px;
    z-index: 100;
}
.logoCompany{
    height: 50px;
    width: 50px;
    background-color: white;
    border-bottom-width: 1;
    border-bottom-color: black;
    border-radius: 25px;
    color: blue;

}
.companyTitle{
    font-size: 24px;
    margin-left: 10px;
    vertical-align: center;
}
.pageHeaderModule{
    height: 60px;
    background-color: #00aced; /* #7b8188; */
    color:white;
    vertical-align: center;
}

.pageHeaderMenu{
    height: 50px;
    background-color: #13B5EA;
    color:white;
    padding-left: 60px;
    padding-top: 10px;
}

.pageHeaderMenu button{
    margin-left: 5px;
    height: 15px;
}
.pageHeaderMenuButton{
    margin-left: 5px;
    font-weight: bold;
    border-right-color: white;
    border-right-style: solid;
    border-right-width: 1px;
    padding-right: 10px;
}
.pageInfoSelected{
    padding-top: 5px;
}


.pageHeader{
    margin-top: -0px;
    height: 100px;
    background-color: white;
    color:black;
}


body{
    margin: 0;
}


.wallpaper {
    background-image: url(/static/media/HomePagePic1.aef8928c.jpg);

    /* Full height */
    height: auto;
    min-height: 100vh;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top:0;
    bottom:0;
    overflow: auto;
}

.overlay{
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top:0;
    bottom:0;
    height: 100%;
    width: 100%;
    z-index: -5;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.myTitleLogin{
    color: #7b8188;
    font-size: 16px;
    font-family: "cursive";
    padding-bottom: 10px;
    text-underline: black;
    border-bottom-width: 1px;
    border-bottom-color: white;
    border-bottom-style: solid;

}
.loginContentBox{
   /* height: 53%;*/
    width: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 5%;
}

.registerContentBox{
    width: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 5%;
    overflow: scroll;
    max-height: 80%;
}

.clickMe{
    cursor: pointer;
}

.pageContentBox{

   /* width: 100%;
    min-height: 75%;*/
    width: 1250%;
    min-height: 75%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 2%;
}

.headerLogout{
    color: lightcoral;
    text-decoration: underline;

}

.headerMenuSelectSecondBar{
    color: yellow;
    font-weight: bold;
    font-size: 16px;
}
.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
}

.slide button {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide p {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide section * {
    transition: all 0.3s ease;
}

.slide section img {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
}

.slide section span {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
}

.slide section span strong {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-delay: .9s;
    opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
    transition-delay: 1.5s;
}

.slide.animateOut h1 {
    transition-delay: .3s;
}

.slide.animateOut p {
    transition-delay: .2s;
}

.slide.animateOut section span {
    transition-delay: .1s;
}

.slide.animateOut section span strong {
    transition-delay: 0s;
}
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: #000000;
    z-index: 10;
}

header section {
    max-width: 1300px;
    margin: auto;
    padding: 20px;
    background: #FFD800;
}

@media (max-width: 1515px) {
    header section {
        max-width: 100%;
    }
}

header section .logo {
    -webkit-transform: translateX(-87px);
            transform: translateX(-87px);
}

header section a {
    float: right;
    padding: 3px 0;
}

* {
    outline: none;
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all .5s ease;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    transition: all .5s ease;
    border: none;
    background: #FFD800;
    border-radius: 30px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 15px 40px;
    font-weight: 400;
    font-size: 13px;
    cursor: cursor;
}

button:hover {
    color: #FFFFFF;
    background: #222222;
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
    max-width: 1335px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
}

.wrapper a {
    display: inline-block;
    margin: 5px;
}

.wrapper a:first-child {
    margin-left: 0;
}

.slider-wrapper {
    position: relative;
    height: 70vh;
    overflow: hidden;
}

.slide {
    height: 70vh;
    background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
    background: url('http://svgshare.com/i/41_.svg') no-repeat center center / 16px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover, .nextButton:hover {
    background: url('http://svgshare.com/i/41_.svg') no-repeat center center / 16px;
}

.previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
    transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
    left: -10px;
}

.nextButton {
    right: 0;
}

.nextButton:hover {
    right: -10px;
}

.slider-content {
    text-align: center;
}

.slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.slider-content .inner button{
    transition-delay: 0s;
}

.slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
}

.slider-content .inner p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.slider-content section span {
    color: #FFFFFF;
}

.slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
}

.slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
}

.slider-content section span strong {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
}

@media (max-height: 500px) {
    .slider-wrapper, .slide {
        height: calc(100vh - 75px);
    }
}

@media (max-width: 640px) {
    .slider-wrapper, .slide {
        height: calc(80vh - 75px);
    }
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}


.modal-dialog {
    text-align: left;
    margin: 0 auto 0;
    left: 25%;
    margin-top: 5%;
    background: lightgrey;
    width: 70%;

    position: absolute;


}

.myModalPosition{
    width: 90%;
    background: inherit;
}
.clickMe{
    cursor: pointer;
    color: blue;
}
.mybuttonOrg{
    text-align: right;
}
 tr.noborder td{
     border-color: orangered;
    border-top: none !important;

}
.page{
    height: 100%;
    display: inline-block;
}
.pageContentWhite{
    width: 100%;
    height: auto;
    background: white;
    position: relative;
    margin-top:0px;
    vertical-align: top;
    top:-10px;
}

.borderShadon{
    border: 2px double;
    border-color: lightgray;
    padding: 10px;
    box-shadow: 5px 10px 18px gray;
    min-height: calc(100vh - 350px);
}
.cOrg12{
    width: 30%;
}
.cOrg13{
    width: 20%;
    text-align: left;
    background: yellow;
}

.logoColoum{
    height: 90px ;
    width: 130px ;
    background-color: #666d74;
    padding-top: 5px;

}
.myLogo{
    background-color: #fcf8e3;
    min-heightheight: 150px ;
    min-width: 150px ;

}
.myImgBackground{
    background-color: white;
}

.select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    display: block;
    width: 100%;
    height: 34px;
}

.loader{
    position: absolute;
    margin: 0 auto 0;
    margin-top: -18%;
    margin-left: 25%;
}

.loader p{
    text-align: center;
    font-size: 18px;
    color: red;
    font-weight: bold;
}

div.siteTableContainer{
    width: 90%;

}

.mySiteTable{
    width: 100%;
    position: relative;
}
.mySiteTable td,th{
    text-align: center;
}
button.date-picker-custom-input{
    width: 100%;
    position: relative;
}

.empPayRates{
    color: red;
    font-size: 14px;
}

.myclocks table{
    overflow-x: scroll;
    background: yellow;

}
.myClassGray td{
    color:  	#DCDCDC;
}

img.imgContractDash{
    width: 99%;
    margin: 0 auto;
}

.centerContent{
    padding: 10px;
}
.ImportEmployeeDatabaseCsvTitle{
    text-align: center;
    font-size: 25px;
}
.totDatabaseQuery{
    bottom: 40px;
    right: 40px;
}
.myBoxListItemDoc{
   padding-left:60px;
}
.hidenMe{
    visibility: hidden;
}

.tAlignLeft{
    text-align: left;
}
.tAlignRight{
    text-align: right;
}
.tAlignCenter{
    text-align: center;
}
table.grayTableHeader th{
    color: #818a91;
    opacity: 0.8;
}
.myFiterHeader{
    font-size: 18px;
    font-weight: bold;
}

table.tbSiteReport{
    background-color: lightgrey;
}
table.tbSiteReport td{
    font-size: 16px;
    font-weight: bold;
    color: black;
}
table.tbSiteReport th{
    color: darkgrey;
}
table.tbSiteReport th.vtable {
    vertical-align: middle;
    padding: 20px;
    font-size: 18px;
    color: black;
}
.myFirstRowCol{
    font-weight: bold;
    font-size: 14px;
}

table.pastelTable th{
    text-align: left;
}

.pastelHeading{
    color: red;
}
.pastelDownloadBtn{
    height: 30px;
    cursor: pointer;
    color: #3f51b5;
}
.pastelTitleDetail{
    color: #4f565d;
}
.tab-header{
    background-color: black;
}



.menuTitle{
    color: #00b3ee;
    font-size: 18px;
}
.menuTitle i, .menuTitle span{
    color: #00b3ee;
}

.menuDetail{
    padding-left: 25px;
}

.settingMenu{
    margin-left: 30%;
}

.settingMenu hr{
    width: 100%;
    position: relative;
    right: 20%;
}

div.myline{
    border-bottom: 5px solid dimgrey;
    width: 35%;
    margin-bottom: 10px;
}


button.addButton{
    margin-right: 20px;
    margin-bottom: 10px;
}

.mycompliance{
    width: 100%;
    overflow: scroll;
}
