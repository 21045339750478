.menuTitle{
    color: #00b3ee;
    font-size: 18px;
}
.menuTitle i, .menuTitle span{
    color: #00b3ee;
}

.menuDetail{
    padding-left: 25px;
}

.settingMenu{
    margin-left: 30%;
}

.settingMenu hr{
    width: 100%;
    position: relative;
    right: 20%;
}

div.myline{
    border-bottom: 5px solid dimgrey;
    width: 35%;
    margin-bottom: 10px;
}
